<template>
    <div class="mt-3">
        <inner-page-card :scrollbar="true">

            <template #header-area>
                <div class="fl-x">
                    <span class="mr-2">
                        <img src="../../assets/web/icons/header-icons/auction-list-icon.png" class="icon-sm" alt="">
                    </span>
                    <p class="font-inter-medium text-center d-inline fs-lg-2 m-0">Auction List</p>
                </div>
            </template>

            <template #default>

                <auctions-list-filter :is_draft_auction="false" :model="filter" @setValue="setValue"/>

                <custom-vue-table class="d-sm-none" :fields="getFields()" :url="listUrl" :per-page="10" ref="table"
                                  :extra-params="{
                            property_type: filter.propertyType,
                            state: filter.state,
                            district: filter.district,
                            status: filter.status,
                            bank: filter.bank,
                            ext_search: filter.ext_search,
                            is_third_party: filter.is_third_party
                           }" :show-search-box="false">
                    <template #is_third_party="{rowData}">
                        <span v-if="rowData.is_third_party">Other</span>
                        <span v-else>Bank E Auctions India.com</span>
                    </template>

                    <template slot="actions" slot-scope="props">
                        <div class="btn-group-tight">
                            <btn design="" icon="fa fa-eye"
                                class="border-gray-hover p-2 text-5"
                                @click="setView(props.rowData)"/>

                            <btn design="" icon="fa fa-pencil" class="border-gray-hover p-2 text-5"
                                 @click="setEdit(props.rowData)"
                                 v-if="(props.rowData.status !== 'Completed' && props.rowData.status !== 'Ended' && props.rowData.status !== 'Started' && props.rowData.status !== 'Suspended') &&
                                 (currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_edit_auction)"/>

                            <btn design="" icon="fa fa-trash-o" class="border-gray-hover p-2 text-5"
                                 @click="deleteItem(props.rowData)"
                                 v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_delete_auction)
                                        && props.rowData.status === ''"></btn>
                        </div>
                    </template>

                </custom-vue-table>

                <delete-modal ref="userDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                    <p>You are about to delete the User <b v-html="deletingItem && deletingItem.name"></b>. Are you sure
                        ?
                    </p>

                    <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                    </template>
                </delete-modal>

                <auctions-card-mobile class="d-lg-none" :property_type="filter.propertyType" :state="filter.state"
                                      :district="filter.district"
                                      :status="filter.status"
                                      :bank="filter.bank"
                                      :is_third_party="filter.is_third_party"
                                      :ext_search="filter.ext_search"
                                      @view="setView" @edit="setEdit" @delete="deleteItem"></auctions-card-mobile>

            </template>

        </inner-page-card>
    </div>
</template>

<script>
import InnerPageCard from '../../components/ui/InnerPageCard';
import AuctionsListFilter from '../../components/auction/auctions-list/AuctionsListFilter';
import AuctionsCardMobile from '../../components/auction/auctions-list/AuctionsCardMobile';
import urls from '@/data/urls';
import { mapGetters } from 'vuex';

export default {
    name : 'AuctionsListPage',

    components : { AuctionsCardMobile, AuctionsListFilter, InnerPageCard },

    data () {
        return {
            deleteUrl    : urls.auction.auction.delete,
            deletingItem : null,
            editingItem  : null,
            status       : this.$route.params.status,

            listUrl : urls.auction.auction.list,

            filter : {
                propertyType : '',
                state        : '',
                district     : '',
                status       : '',
                ext_search   : '',
                bank         : ''
            },

            fields : [
                {
                    name      : 'auction_id',
                    dataClass : 'aligned text-center',
                    title     : 'Auction ID'
                },
                {
                    name  : 'title',
                    title : 'Auction Name'
                },
                {
                    name  : 'event_bank.bank_name',
                    title : 'Bank/FI'
                },
                {
                    name  : 'state',
                    title : 'State'
                },
                {
                    name  : 'district',
                    title : 'District'
                },
                {
                    name  : 'created_date',
                    title : 'Created Date'
                },
                {
                    name  : 'auction_start_date',
                    title : 'Auction Date'
                },
                {
                    name      : 'participant_count',
                    dataClass : 'text-center',
                    title     : 'Participants'
                },
                {
                    name  : 'status',
                    title : 'Status'
                },
                {
                    name  : '__slot:is_third_party',
                    title : 'Platform'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ]
        };
    },

    watch : {
        '$route.params.status' : {
            handler () {
                this.setStatus();
            }
        }
    },

    beforeMount () {
        this.setStatus();
    },

    computed : {
        filters () {
            return this.filter;
        },

        ...mapGetters(['currentUser'])
    },

    methods : {

        setStatus () {
            if (this.status) {
                this.filter.status = this.$route.params.status;
            }
        },

        setView (rowData) {
            console.log('rowData : ', rowData);
            this.$router.push({ path : '/auction/' + rowData.auction_id + '/details/' });
        },

        setValue (data) {
            this.filter[data.key] = data.value;
        },

        setEdit (item) {
            this.$router.push('/edit/' + item.auction_id + '/auction/');
        },

        deleteItem (item) {
            const instance = { id : item.auction_id };
            this.deletingItem = { ...instance };

            this.$refs.userDeleteModal.show();
        },

        deleteComplete (response) {
            this.$refs.userDeleteModal.close();
            const json = response.data;
            if (json.error === false) {
                this.$notify('Successfully deleted', 'success',
                    {
                        type      : 'success',
                        duration  : 3000,
                        permanent : false
                    });
                this.$refs.table.refreshTable();
            } else {
                this.deleteFailure(json);
            }
        },

        deleteFailure (json) {
            console.log(json);
            this.$notify(json.errors, 'Error',
                {
                    type      : 'warning',
                    duration  : 3000,
                    permanent : false
                }
            );
        },

        getFields () {
            if (!this.currentUser.is_bank_admin) {
                return this.fields;
            } else {
                return [
                    {
                        name      : 'auction_id',
                        dataClass : 'aligned text-center',
                        title     : 'Auction ID'
                    },
                    {
                        name  : 'title',
                        title : 'Auction Name'
                    },
                    {
                        name  : 'state',
                        title : 'State'
                    },
                    {
                        name  : 'district',
                        title : 'District'
                    },
                    {
                        name  : 'created_by',
                        title : 'Created By'
                    },
                    {
                        name  : 'created_date',
                        title : 'Created Date'
                    },
                    {
                        name      : 'participant_count',
                        dataClass : 'text-center',
                        title     : 'Participants'
                    },
                    {
                        name  : 'status',
                        title : 'Status'
                    },
                    {
                        name  : '__slot:is_third_party',
                        title : 'Platform'
                    },
                    {
                        name       : '__slot:actions',
                        titleClass : 'center aligned text-right',
                        dataClass  : 'aligned text-right',
                        title      : 'Actions'
                    }
                ];
            }
        }
    }

};
</script>

<style scoped lang="scss">
.pos-a-b {
    position: absolute;
    bottom: 0;
}
</style>
