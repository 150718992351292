var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('inner-page-card',{attrs:{"scrollbar":true},scopedSlots:_vm._u([{key:"header-area",fn:function(){return [_c('div',{staticClass:"fl-x"},[_c('span',{staticClass:"mr-2"},[_c('img',{staticClass:"icon-sm",attrs:{"src":require("../../assets/web/icons/header-icons/auction-list-icon.png"),"alt":""}})]),_c('p',{staticClass:"font-inter-medium text-center d-inline fs-lg-2 m-0"},[_vm._v("Auction List")])])]},proxy:true},{key:"default",fn:function(){return [_c('auctions-list-filter',{attrs:{"is_draft_auction":false,"model":_vm.filter},on:{"setValue":_vm.setValue}}),_c('custom-vue-table',{ref:"table",staticClass:"d-sm-none",attrs:{"fields":_vm.getFields(),"url":_vm.listUrl,"per-page":10,"extra-params":{
                        property_type: _vm.filter.propertyType,
                        state: _vm.filter.state,
                        district: _vm.filter.district,
                        status: _vm.filter.status,
                        bank: _vm.filter.bank,
                        ext_search: _vm.filter.ext_search,
                        is_third_party: _vm.filter.is_third_party
                       },"show-search-box":false},scopedSlots:_vm._u([{key:"is_third_party",fn:function({rowData}){return [(rowData.is_third_party)?_c('span',[_vm._v("Other")]):_c('span',[_vm._v("Bank E Auctions India.com")])]}},{key:"actions",fn:function(props){return [_c('div',{staticClass:"btn-group-tight"},[_c('btn',{staticClass:"border-gray-hover p-2 text-5",attrs:{"design":"","icon":"fa fa-eye"},on:{"click":function($event){return _vm.setView(props.rowData)}}}),((props.rowData.status !== 'Completed' && props.rowData.status !== 'Ended' && props.rowData.status !== 'Started' && props.rowData.status !== 'Suspended') &&
                             (_vm.currentUser.is_superuser || _vm.currentUser.is_bank_admin || _vm.currentUser.permission.can_edit_auction))?_c('btn',{staticClass:"border-gray-hover p-2 text-5",attrs:{"design":"","icon":"fa fa-pencil"},on:{"click":function($event){return _vm.setEdit(props.rowData)}}}):_vm._e(),((_vm.currentUser.is_superuser || _vm.currentUser.is_bank_admin || _vm.currentUser.permission.can_delete_auction)
                                    && props.rowData.status === '')?_c('btn',{staticClass:"border-gray-hover p-2 text-5",attrs:{"design":"","icon":"fa fa-trash-o"},on:{"click":function($event){return _vm.deleteItem(props.rowData)}}}):_vm._e()],1)]}}])}),_c('delete-modal',{ref:"userDeleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the User "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". Are you sure ? ")])]),_c('auctions-card-mobile',{staticClass:"d-lg-none",attrs:{"property_type":_vm.filter.propertyType,"state":_vm.filter.state,"district":_vm.filter.district,"status":_vm.filter.status,"bank":_vm.filter.bank,"is_third_party":_vm.filter.is_third_party,"ext_search":_vm.filter.ext_search},on:{"view":_vm.setView,"edit":_vm.setEdit,"delete":_vm.deleteItem}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }