<template>

    <div class="d-lg-none">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>

            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-4">

                <div class="gr-3-cols ">
                    <div class="fl-gr-1 b-1 py-2">
                        <div class="fl-x fl-j-c py-2 fl-a-c">
                            <div class="pos-r ">
                                <div class=""></div>
                                Auction ID
                            </div>
                        </div>
                    </div>
                    <div class="fl-gr-1 b-1 py-2">
                        <div class="fl-x fl-j-s fl-j-c  py-2 fl-a-c">
                            <div class="pos-r pl-2">
                                <div class=""></div>
                                Auction Name.
                            </div>
                        </div>
                    </div>
                    <div class="fl-gr-1 b-1 py-2">
                        <div class="fl-x fl-j-c fl-j-c  py-2 fl-a-c">
                            <div class="pos-r">
                                <div class="line-right-1"></div>
                                Bank/FI
                            </div>
                        </div>
                    </div>

                </div>
                <div class="b-1 bs-3">
                    <div class="gr-3-cols font-inter-medium c-line-bottom">
                        <!--                <div class="line-bottom"></div>-->
                        <div class="br-1 py-2">
                            <div class="fl-x fl-j-c fl-a-c ">
                                <div class="pos-r  pl-5 pr-5">
                                    <div class=""></div>
                                    {{ i.auction_id }}
                                </div>
                            </div>
                        </div>
                        <div class="py-2">
                            <div class="pos-r fl-j-c pl-3 pr-2 ">
                                <div class=""></div>
                                {{ i.title }}
                            </div>
                        </div>
                        <div class="bl-1 py-2">
                            <div class="pos-r fl-j-c pl-1">
                                <!--                                <div class="line-right-1"></div>-->
                                {{ i.event_bank.bank_name }}
                            </div>
                        </div>
                    </div>
                    <div class="row b-1 fs--1 m-0">

                        <span class="b-1 col-6 w-100 col-12">
                    <div class="p-1">
                        State&nbsp;&nbsp;: <span class="ml-2">{{ i.state }}</span>
                    </div>
                </span>

                        <span class="b-1 col-6 w-100 col-12">
                    <div class="p-1">
                        District&nbsp;: <span class="ml-2">{{ i.district }}</span>
                    </div>
                </span>

                        <span class="b-1 col-6 w-100 col-12">
                    <div class="p-1">
                         Created Date : <span class="ml-2">
                    {{ i.created_date }}</span>
                    </div>
                </span>
                        <span class="b-1 col-12 w-100 col-12">
                    <div class="p-1">
                         Created By :
                        <span v-if="i.created_by" class="ml-2">{{ i.created_by }}</span>
                    </div>
                </span>
                        <span class="b-1 col-12 w-100 col-12">
                    <div class="p-1">
                        Auction Date : <span class="ml-2">
                    {{ i.auction_start_date }}</span>
                    </div>
                </span>
                        <span class="b-1 col-12 w-100 col-12">
                    <div class="p-1">
                         No of Participants : <span class="ml-2">
                    {{ i.participant_count }}</span>
                    </div>
                </span>
                        <span class="b-1 col-12 w-100 col-12">
                    <div class="p-1">
                         Status :
                        <span v-if="i.created_by" class="ml-2">{{ i.status }}</span>
                    </div>
                </span>
                        <span class="b-1 col-12 w-100 col-12">
                    <div class="p-1">
                         Platform :
                        <span v-if="!i.is_third_party" class="ml-2">Bank e Auctions India.com</span>
                        <span v-else class="ml-2">Other</span>
                    </div>
                </span>
                        <span class="b-1 col-6 ">
                    <div class="pt-2 pl-2 font-inter-medium ">
                         Actions
                    </div>
                </span>
                        <span class="b-1 col-6">
                    <div class="p-1 fl-x fl-j-c">
                        <div class="btn-group">

                            <btn design="" icon="fa fa-eye" class="border-gray-hover p-2 text-5"
                                 @click="setView(i)"/>

                            <btn design="" icon="fa fa-pencil" class="border-gray-hover p-2 text-5"
                                 @click="setEdit(i)"
                                 v-if="(i.status !== 'Completed' && i.status !== 'Ended' && i.status !== 'Started' && i.status !== 'Suspended') &&
                                 (currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_edit_auction)"/>

                            <btn design="" icon="fa fa-trash-o" class="border-gray-hover p-2 text-5"
                                 @click="deleteItem(i)"
                                 v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_delete_auction)
                                        && i.status === ''"></btn>

                        </div>
                    </div>
                </span>
                    </div>
                </div>
            </div>
            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../../data/urls';
import _ from 'lodash';

export default {
    name : 'AuctionsCardMobile',

    props : {
        property_type : {
            type : String
        },

        state : {
            type : String
        },

        district : {
            type : String
        },

        status : {
            type : String
        },

        bank : {
            type : String
        },

        is_third_party : {
            type    : Boolean,
            default : false
        },

        ext_search : {
            type : String
        }
    },

    watch : {
        property_type () {
            this.timeOutLoadData();
        },

        state () {
            this.timeOutLoadData();
        },

        district () {
            this.timeOutLoadData();
        },

        status () {
            this.timeOutLoadData();
        },

        bank () {
            this.timeOutLoadData();
        },

        is_third_party () {
            this.timeOutLoadData();
        },

        ext_search () {
            this.timeOutLoadData();
        }
    },

    data () {
        return {
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '10',
            loading  : true,
            error    : false,
            details  : null
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        timeOutLoadData : _.debounce(function () {
            this.loadData();
        }, 2000),
        loadData () {
            const that = this;
            that.loading = true;
            that.error = false;
            // Make the actual API call
            axios.get(urls.auction.auction.list, {
                params : {
                    page           : that.page,
                    per_page       : that.per_page,
                    property_type  : that.property_type,
                    state          : that.state,
                    district       : that.district,
                    status         : that.status,
                    bank           : that.bank,
                    is_third_party : that.is_third_party,
                    ext_search     : that.ext_search
                }
            }).then(function (response) {
                const json = response.data;
                that.details = { ...json };
                that.loading = false;
                that.error = false;
                that.setPagination();
            }).catch(function (exception) {
                console.log('exception: ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        },

        setView (item) {
            this.$emit('view', item);
        },

        setEdit (item) {
            this.$emit('edit', item);
        },

        deleteItem (item) {
            this.$emit('delete', item);
        }

    }

};
</script>

<style scoped>

</style>
